<template>
  <AuthLayoutContent>
    <template v-slot:left>
      <div class="step mx-auto">
        <Spinner class="m-auto" v-if="loading" />
        <div class="w-100" v-else>
          <h1>{{ $t("Upload additional income documents") }}</h1>
          <p class="fw-medium text-gray">
            {{
              $t(
                "Because we cannot verify your income via online banking , we require additional documents and information."
              )
            }}
          </p>
          <div>
            <div
              class="d-flex mt-4 justify-content-between"
              v-for="(file, key) in files"
              :key="key"
            >
              <div>
                <strong>{{ $t(file.title) }}</strong>
                <div class="text-success fileName" v-if="file.name">
                  <a :href="file.url" target="_blank">
                    {{ file.name }}
                  </a>
                </div>
              </div>
              <input
                type="file"
                :ref="file.type"
                :name="file.type"
                @change="filesChange($event, key)"
                class="input-file"
              />
              <div>
                <button
                  class="btn btn-primary disabled"
                  v-if="['submitted', 'approved'].includes(file.status)"
                >
                  <i class="fa-regular fa-file-lines"></i>
                  {{ $t("Select File") }}
                </button>
                <button
                  class="btn btn-outline-primary"
                  v-else
                  @click="selectFile(file.type)"
                  type="button"
                  :disabled="file.uploading || file.status == 'submitted'"
                >
                  <b-spinner
                    v-if="file.uploading"
                    small
                    label="Spinning"
                    variant="primary"
                    class="mr-2"
                  >
                  </b-spinner>
                  <i class="fa-regular fa-file-lines"></i>
                  {{ $t("Select File") }}
                </button>
              </div>
            </div>
          </div>
          <small class="text-danger" v-if="getErrors.file_upload">
            {{ getErrors.file_upload }}
          </small>
          <small class="text-danger" v-if="error">
            {{ error }}
          </small>
          <div class="w-100 mt-4" v-if="!allFilesApproved">
            <button
              type="button"
              class="btn btn-primary btn-block"
              :disabled="submitting || !fileUploaded"
              @click="submitFiles"
            >
              {{ $t("Next step") }}
              <b-spinner v-if="submitting" small label="Spinning" variant="white">
              </b-spinner>
            </button>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:right>
      <RightSection>
        <div class="step__rightSection mx-auto">
          <RightPoints active="5" />
        </div>
      </RightSection>
    </template>
  </AuthLayoutContent>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import RightSection from "@/components/common/RightSection.vue";
import AuthLayoutContent from "@/components/common/AuthLayoutContent.vue";
import RightPoints from "../Expat/RightPoints.vue";
import Spinner from "@/components/ui/Spinner.vue";

export default {
  data() {
    return {
      loading: false,
      files: [],
      error: "",
      submitting: false,
    };
  },
  components: {
    RightSection,
    AuthLayoutContent,
    RightPoints,
    Spinner,
  },
  computed: {
    ...mapGetters("loadingError", ["getLoadings", "getErrors"]),
    ...mapGetters("nordigen", ["accounts"]),
    ...mapGetters("auth", ["user"]),
    ...mapGetters("tenant", ["tenant"]),

    allFilesApproved() {
      for (let file of this.files) {
        if (!(file.status == "approved" || file.status == "submitted")) {
          return false;
        }
      }

      return true;
    },
    fileUploaded() {
      for (let file of this.files) {
        if (!file.url) {
          return false;
        }
      }

      return true;
    },
  },
  async created() {
    this.loading = true;
    await this.getTenant(this.$route.params.tenant_id);
    this.step = +this.$route.params.step;
    let file_templates = [
      {
        title: "Payment slips (last 3 months)",
        name: "",
        type: "payment_slip",
        uploading: false,
        url: "",
      },
      {
        title: "Employer's statement",
        name: "",
        type: "employers_statement",
        uploading: false,
        url: "",
      },
      {
        title: "Employee contract",
        name: "",
        type: "employee_contract",
        uploading: false,
        url: "",
      },
    ];

    try {
      let response = await this.getFiles();
      let files = response.files;
      for (let file of file_templates) {
        let index = files.findIndex((x) => x.type == file.type);

        if (index != -1) {
          this.files.push({ ...files[index], uploading: false });
        } else {
          this.files.push(file);
        }
      }

      this.loading = false;
    } catch (error) {
      this.loading = false;
      console.error("Error", error);
    }
  },
  methods: {
    ...mapActions("tenant", ["getFiles", "getTenant", "uploadFile"]),

    selectFile(type) {
      console.log("type", type, this.$refs);
      let fileInputElement;
      this.file_type = type;
      fileInputElement = this.$refs[type][0];
      fileInputElement.click();
    },
    async filesChange($event, index) {
      let file_type = this.file_type;
      let files = $event.target.files;
      let file = files[0];

      console.log("file");
      let extension = file.name.split(".").pop();
      this.files[index].name = "";
      this.files[index].url = "";
      let allowed_types = ["pdf", "png", "jpg", "jpeg"];

      if (!allowed_types.includes(extension.toLowerCase())) {
        this.error = "*Only image and PDF is allowed";
        return;
      }

      if (file.size / 1024 / 1024 > 10) {
        this.error = "*Maximum file size is 10mb";
        return;
      }

      this.files[index].uploading = true;
      try {
        let response = await this.uploadFile({
          type: file_type,
          file,
        });
        this.files[index].name = response.file.name;
        this.files[index].url = response.file.url;
      } catch (error) {
        console.log("Error", error);
      }
      this.files[index].uploading = false;
    },
    submitFiles() {
      this.submitting = true;
      window.axios
        .post(`/user/tenant/${this.tenant.id}/file/submit`)
        .then(() => {
          this.$router.push({
            name: "OnboardingSuccess",
          });
        })
        .catch(({ response, message }) => {
          this.message = response?.data?.message ?? message;
        })
        .finally(() => {
          this.submitting = false;
        });
    },
  },
};
</script>

<style lang="scss">
body {
  @include md {
    background: white;
  }
}

.step {
  max-width: 412px;

  @include md {
    padding-top: 62px;
  }

  @include xs {
    max-width: 90%;
  }

  &__heading {
    @include font(1.375rem, $primary, 700);
  }

  &__rightSection {
    width: 362px;

    @include xs {
      width: 93%;
    }
  }
}

.input-file {
  display: none;
}

.fileName {
  max-width: 200px;
  word-break: break-all;
}
</style>
